nav {
    /* max-height: 72.5px; */
    box-shadow: 0 2px 10px 0 rgba(255, 255, 255, 0.18);
    background-color: #212121;
}

.nav-link {
    display: initial;
}

.nav-link:hover {
    transform: scale(1.3);
    color: rgb(241, 189, 68) !important;

}

.navbar .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
}

.navbar-toggler:focus {
    box-shadow: 0 0 0 0.25px white !important;
    border: none !important;

}

nav a,
nav button {
    color: white;
    text-decoration: none;
    background: transparent;
}

.navbar-collapse {
    flex-grow: initial !important;
}

.navbar-collapse .d-flex {
    flex-direction: column;
}

.navbar-collapse .d-flex h6 {
    display: flex;
    flex-direction: column;
}

.navbar-nav button {
    color: #f8f9fa !important;
    font-weight: bold;
}

@media screen and (min-width: 768px) {
    nav {
        height: 72.5px;
    }

    .navbar-nav button {
        color: pink !important;
    }

    .navbar-nav .nav-link {
        padding-left: initial;
        padding-right: initial;
    }

    .navbar-collapse .d-flex {
        flex-direction: initial;
    }

    .navbar-collapse .d-flex h6 {
        display: initial;
    }


}
.small-intro {
  font-family: monospace;
}

.modal-header {
  border: none;
}

#skills,
#connection,
#projects {
  margin-top: 5.5%;
}

/* #projects {
  margin-top: 3.5%;
} */
.footer strong {
    color: white
}

.footer strong::after,
.footer strong::before {
    display: inline-block;
    content: "";
    border-top: .15rem solid #2c2c2c;
    width: 45%;
    margin: 0 1rem;
    transform: translateY(-4px);
    /* box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.15); */
}
@import url(//db.onlinewebfonts.com/c/50866396b50148c96c2b2d6863de46a6?family=FriendorFoeTallBB);

@font-face {
    font-family: "FriendorFoeBB";
    src: url("//db.onlinewebfonts.com/t/262e61977221d8478c4e05291da30122.eot");
    src: url("//db.onlinewebfonts.com/t/262e61977221d8478c4e05291da30122.eot?#iefix") format("embedded-opentype"),
        url("//db.onlinewebfonts.com/t/262e61977221d8478c4e05291da30122.woff2") format("woff2"),
        url("//db.onlinewebfonts.com/t/262e61977221d8478c4e05291da30122.woff") format("woff"),
        url("//db.onlinewebfonts.com/t/262e61977221d8478c4e05291da30122.ttf") format("truetype"),
        url("//db.onlinewebfonts.com/t/262e61977221d8478c4e05291da30122.svg#FriendorFoeBB") format("svg");
}

.modal-title-border {
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: rgb(241, 189, 68);
    margin-left: 3.5%;
    margin-right: 3.5%;
}

.modal-content {
    color: rgb(249, 198, 49);
    background-image: linear-gradient(#4E4C50, #090100);
    border-radius: 0%;
    border: solid 1.5px;
    border-image-source: linear-gradient(#D19F54, #574936);
    border-image-slice: 10;
    font-family: 'FriendOrFoeBB';
    text-transform: uppercase !important;
    text-shadow: #FC0 0px 0 4px;
    letter-spacing: 0.05rem;
    font-size: large;
}

.modal-content button {
    background-color: transparent;
    color: rgb(249, 198, 49);
    border-radius: 0%;
    border-image-source: linear-gradient(#D19F54, #574936);
    border-image-slice: 10;
    transition: transform(1s);
}

.modal-content input,
.modal-content textarea {
    background-color: transparent;
    border-style: inset !important;
    border-color: rgb(118, 118, 118);
    border-width: 1px;
    border-image: initial;
    color: rgb(249, 198, 49);
    font-size: 20px;

}

.modal-content input:focus,
.modal-content textarea:focus {
    background-color: transparent;
    color: rgb(249, 198, 49);
    outline: none;
    box-shadow: none;
    border-color: white;
    border-style: inset;
    letter-spacing: 1px;
}

.modal-content button:hover {
    transform: scale(1.2);
    background-color: transparent !important;
    color: #f9c631;
}

.btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill='rgb(241,189,68)'%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat
}

.modal-content label,
.modal-submit-container button {
    font-size: 20px;
}

#connection-header {
    display: inline-block;
    --border-size: 3px;
    --border-angle: 0turn;

    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    background-image: conic-gradient(from var(--border-angle),
            #121212,
            #121212 50%,
            #121212),
        conic-gradient(from var(--border-angle), transparent 30%, #f9c73165, #f9c631);
    background-size: calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)),
        cover;
    background-position: center center;
    background-repeat: no-repeat;

    animation: bg-spin 3s linear infinite;
    animation-play-state: paused;
}

@keyframes bg-spin {
    to {
        --border-angle: 1turn;
    }
}

@property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
}

#connection-header:hover {
    color: rgb(241, 189, 68);
    cursor: pointer;
    animation-play-state: running;

}

.btn:disabled {
    background-color: initial !important;
    color: rgb(249, 198, 49);
    text-decoration: line-through;
}

#email-anchor {
    color: rgba(249, 198, 49, 0.8) !important;
    font-size: 17.5px;
}

#email-anchor:hover {
    opacity: 0.7;
}
#about-details {
    line-height: 1.5;
    color: grey !important;
    font-size: 18px;
    font-family: monospace;
}

.Typewriter {
    display: inline;
}

#intro h5 {
    font-size: 22px;
}

#intro h3 {
    font-size: 30px;
}
.skills-comps {
    color: white
}

.skills-icons,
.skill-container {
    font-size: 5vh;
    display: inline;
    margin-left: 1.5vh;
    margin-right: 1.5vh;
    margin-bottom: 2vh;
}

.tooltip-skills {
    visibility: hidden;
    width: 120px;
    background-color: red;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 999;
    margin-top: 100px;
    left: 50%;
    transform: translateX(-50%);
}

.skills-icons:hover .tooltip-skills {
    visibility: visible;

}

.skill-tooltip>.tooltip-inner {
    background-color: transparent !important;
}